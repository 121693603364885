import { render, staticRenderFns } from "./ProductCard.vue?vue&type=template&id=6d26c398&scoped=true&"
import script from "./ProductCard.vue?vue&type=script&lang=ts&"
export * from "./ProductCard.vue?vue&type=script&lang=ts&"
import style0 from "./ProductCard.vue?vue&type=style&index=0&id=6d26c398&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d26c398",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsImageOrFallback: require('/var/www/packages/theme/components/atoms/ImageOrFallback.vue').default,AtomsKELabel: require('/var/www/packages/theme/components/atoms/KELabel.vue').default,AtomsFavoriteButton: require('/var/www/packages/theme/components/atoms/FavoriteButton.vue').default,AtomsRatingStarsBar: require('/var/www/packages/theme/components/atoms/RatingStarsBar.vue').default,IconsCheckmark16px: require('/var/www/packages/theme/components/icons/checkmark/Checkmark16px.vue').default,IconsCheckmark12px: require('/var/www/packages/theme/components/icons/checkmark/Checkmark12px.vue').default,AtomsAddToCartButton: require('/var/www/packages/theme/components/atoms/AddToCartButton.vue').default,AtomsPrice: require('/var/www/packages/theme/components/atoms/Price.vue').default})
